<template>
    <div class="container">
        <!-- 账号登录 -->
        <div v-if="scanQR" class="content">
            <div class="top_operate">
                <!-- <img src="@/assets/images/ic_tuichu@3x.png"  @click="outWeb"/> -->
                <div></div>
                <img @click="scanQRCodes" src="@/assets/images/ic_erweima@3x.png"/>
            </div>
            <div class="logo">
                <img src="@/assets/images/logo@3x.png"/>
            </div>
            <div class="login_info">
                <div class="user">
                    <img src="@/assets/images/ic_手机号码@2x.png"/>
                    <input
                            type="text"
                            placeholder="请输入手机号或蜘信账号"
                            v-model="form.account"
                    />
                </div>
                <div class="pwd">
                    <img src="@/assets/images/ic_密码@2x.png"/>
                    <input
                            type="password"
                            placeholder="请输入密码"
                            v-model="form.password"
                    />
                </div>
            </div>
            <div class="login_button" @click="login">登录</div>
        </div>
        <!-- 扫码登录 -->
        <div v-else class="content">
            <div class="top_operate">
                <!-- <img src="@/assets/images/ic_tuichu@3x.png" /> -->
            </div>
            <div class="qrcode">
                <p>使用蜘信扫一扫以登录 <span style="color:#4db4d4;font-size: 12px;margin-left: 10px;"
                                    @click="creatQrCode">刷新</span></p>

                <!-- <img src="@/assets/images/qrcode.png" /> -->
                <div id="qrCodeUrl" ref="qrCodeUrl"></div>
            </div>
            <!-- <div class="back">
              <span @click="handleBack">
                <i class="iconfont el-icon-arrow-left"></i>
                <span> 账号登录</span>
              </span>
            </div> -->
        </div>
    </div>
</template>

<script>
    import JSONbig from "json-bigint";
    import api from "@/api/api";
    import QRCode from "qrcodejs2";

    export default {
        name: "login",
        data() {
            return {
                scanQR: false, //切换账号和二维码登录
                form: {
                    //登录数据
                    account: "",
                    password: "",
                    type: 1, //登录方式 1密码登录 2二维码登录
                },
                uuid: "", //本机唯一标识
                qrcode: null, //二维码实例 没有也可以
                timer: null,
                // websoketPath: "ws://47.96.129.164:7777/ws", //线上
                // websoketPath: "wss://socket.zhizhumaimai.com/ws", //线上
                // websoketPath:"ws://192.168.31.54:7777/ws", //线下
                socket: "", //websoket实例
            };
        },
        created() {
            this.getUuid();
            this.init();
            setTimeout(() => {
                this.scanQRCodes();
            }, 400);
        },
        mounted() {
            // this.creatQrCode()
        },
        methods: {
            // 跳转扫描二维码登录
            scanQRCodes() {
                let obj = {};
                obj.cmd = "10000";
                obj.data = {
                    uuid: this.uuid,
                };

                if (this.socket.readyState === 1) {
                    this.send(JSON.stringify(obj));
                    this.scanQR = false;
                    setTimeout(() => {
                        this.creatQrCode();
                    }, 200);
                } else {
                    setTimeout(() => {
                        this.scanQRCodes()
                    }, 400)
                }
                // console.log("===", JSON.stringify(obj));
            },
            // 返回账户登录
            handleBack() {
                //先清除二维码
                this.isShowOpen();
                this.scanQR = true;
            },
            //生成二维码
            creatQrCode() {
                //如果websocket还没有连接开启成功直接重新刷新整个网页
                if (this.socket.readyState !== 1) {
                    window.location.reload(true);
                    return;
                }
                const loading = this.$loading({
                    target: '.content',
                    lock: true,
                    text: '二维码生成中',
                    fullscreen: false
                });
                if (this.$refs.qrCodeUrl) {
                    this.$refs.qrCodeUrl.innerHTML = "";
                }
                this.qrcode = new QRCode("qrCodeUrl", {
                    text: "SpiderLetter://login/scanLogin?uuid=" + this.uuid, // 需要转换为二维码的内容
                    width: 200, // 在这设置无效
                    height: 200, //在这设置无效
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
                setTimeout(() => {
                    loading.close();
                }, 800);
            },
            //清除生成的二维码
            isShowOpen() {
                const codeHtml = document.getElementById("qrCodeUrl");
                codeHtml.innerHTML = "";
            },
            // 获取uuid
            getUuid() {
                var s = [];
                var hexDigits = "0123456789abcdef";
                for (var i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
                s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
                s[8] = s[13] = s[18] = s[23] = "-";

                var uuid = s.join("");
                this.uuid = uuid;
                // console.log("uuid", uuid);
            },
            //登录
            login() {
                api.index
                    .login(this.form)
                    .then((res) => {
                        if (res.code == "200") {
                            //  console.log(res)
                            localStorage.setItem("loginMsg", JSON.stringify(res.result)); //登录信息存入缓存
                            localStorage.setItem("token", res.result.token); //token存入缓存
                            //同步更新token
                            this.$store.commit("upDateToken", res.result.token);
                            this.$message({
                                showClose: true,
                                message: "登录成功",
                                type: "success",
                            });
                            this.$router.push("/index");
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.message,
                                type: "error",
                            });
                        }
                    })
                    .catch((err) => {
                        this.$message({
                            showClose: true,
                            message: err.message,
                            type: "error",
                        });
                    });
            },
            //初始化websoket
            init() {
                if (typeof WebSocket === "undefined") {
                    alert("您的浏览器不支持socket");
                } else {
                    // 实例化socket
                    this.socket = new WebSocket(this.$websoketUrl);
                    // 监听socket连接
                    this.socket.onopen = this.open;
                    // 监听socket错误信息
                    this.socket.onerror = this.error;
                    // 监听socket消息
                    this.socket.onmessage = this.getMessage;
                }
            },
            //打开websoket连接
            open() {
                console.log("socket连接成功");
            },
            //退出程序
            outWeb() {
                var userAgent = navigator.userAgent;
                if (userAgent.indexOf("MSIE") > 0) {
                    if (userAgent.indexOf("MSIE 6.0") > 0) {
                        window.opener = null;
                        window.close();
                    } else {
                        window.open("", "_top");
                        window.top.close();
                    }
                } else if (
                    userAgent.indexOf("Firefox") != -1 ||
                    userAgent.indexOf("Chrome") != -1
                ) {
                    window.location.href = "about:blank "; //火狐默认状态非window.open的页面window.close是无效的
                    //window.history.go(-2);
                } else {
                    window.opener = null;
                    window.open("about:blank", "_self");
                    window.close();
                }
            },
            //websoket连接错误
            error() {
                console.log("连接错误");
                window.location.reload(true);
            },
            //获取监听的信息
            getMessage(msg) {
                const msgs = JSON.parse(msg.data);
                console.log(msgs);
                if (msgs.cmd == "10001") {
                    localStorage.setItem("loginMsg", JSON.stringify(msgs.data)); //登录信息存入缓存
                    localStorage.setItem("token", msgs.data.token); //token存入缓存
                    clearInterval(this.timer);
                    this.timer = null;
                    // //同步更新token
                    this.$store.commit("upDateToken", msgs.data.token);
                    this.$message({
                        showClose: true,
                        message: "登录成功",
                        type: "success",
                    });
                    this.$router.push("/index");
                }
            },
            //发送信息
            send(params) {
                this.socket.send(params);
            },
            //关闭websoket连接
            close() {
                console.log("socket已经关闭");
            },
        },
        destroyed() {
            // 销毁监听
            this.socket.close();
        }
    };
</script>

<style scoped>
    .container {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        user-select: none;
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: 400px;
        background: white;
        padding: 10px;
        text-align: center;
    }

    .top_operate {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .top_operate img {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    .logo {
        text-align: center;
        margin-top: 34px;
    }

    .logo img {
        width: 95px;
        height: 100px;
    }

    .login_button {
        width: 240px;
        height: 40px;
        line-height: 40px;
        background: #4db4d4;
        border-radius: 42px;
        color: #ffffff;
        font-size: 18px;
        letter-spacing: 2px;
        margin: 0 auto;
        margin-top: 30px;
        cursor: pointer;
    }

    .login_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 51px;
    }

    .login_info .user {
        display: flex;
        align-items: center;
        width: 240px;
        height: 40px;
        border-bottom: 1px solid #dcdfe6;
    }

    .login_info .pwd {
        display: flex;
        align-items: center;
        width: 240px;
        height: 40px;
        border-bottom: 1px solid #dcdfe6;
    }

    .login_info img {
        width: 16px;
        height: 16px;
        margin-right: 12px;
    }

    .login_info input {
        border: none;
        outline: none;
        width: 210px;
    }

    .login_info input::-webkit-input-placeholder {
        color: #abaeb3;
    }

    .qrcode {
        margin-top: 34px;
    }

    .qrcode p {
        margin: 0;
    }

    .qrcode img {
        width: 180px;
        height: 180px;
        margin: 40px 0 68px 0;
    }

    .back {
        text-align: left;
        color: #7a7c80;
    }

    .back span {
        cursor: pointer;
    }

    .back span:hover {
        color: #4db4d4;
    }

    #qrCodeUrl {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 68px;
    }
</style>
